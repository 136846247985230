import React from "react";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CssBaseline from "@material-ui/core/CssBaseline";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import {makeStyles} from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Link from "../components/Link";
import Layout from "../components/Layout";
import {COLORS} from "../utils/colors";

import UltimateFront from "../Garments/UltimateTrousers/UltimateFront";
import DynamixFront from "../Garments/Dynamix/DynamixFront";
import TrendFront from "../Garments/Trend/TrendFront";
import OpticaFront from "../Garments/OpticaHose/OpticaFront";
import ClassicoShortsFront from "../Garments/ClassicoShorts/ClassicoShortsFront";
import ClassicoHoseFront from "../Garments/ClassicoHose/ClassicoHoseFront";
import OverallPrimoFront from "../Garments/OverallPrimo/OverallPrimoFront";
import LatzhosePrimoFront from "../Garments/LatzhosePrimo/LatzhosePrimoFront";

const useStyles = makeStyles((theme) => ({
  icon: {
    marginRight: theme.spacing(2),
  },
  heroContent: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(8, 0, 6),
  },
  heroButtons: {
    marginTop: theme.spacing(4),
  },
  cardGrid: {
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),
  },
  card: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },
  cardMedia: {
    paddingTop: "56.25%", // 16:9
    minHeight: "280px",
    maxWidth: "300px",
    width: "100%",
    align: "center",
  },
  cardContent: {
    flexGrow: 1,
  },
  footer: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(6),
  },
}));

const cards = [
  {
    id: 1,
    heading: "Hose Dynamix",
    type: "Moderne Cargo Hose mit dehnbarem Bund.",
    url: "/dynamix",
    img: (
      <DynamixFront
        lightContrast={COLORS.white.value}
        darkContrast={COLORS.black.value}
        stitching={COLORS.black.value}
        patchColor={COLORS.black.value}
        azomo={COLORS.white.value}
        preview={true}
      />
    ),
  },
  {
    id: 2,
    heading: "Hose Trend",
    type: "Moderne Arbeitshose mit tieferem Bund",
    url: "/trend",
    img: (
      <TrendFront
        lightContrast={COLORS.white.value}
        darkContrast={COLORS.black.value}
        stitching={COLORS.black.value}
        patchColor={COLORS.black.value}
        azomo={COLORS.white.value}
        preview={true}
      />
    ),
  },
  {
    id: 3,
    heading: "Hose Classico",
    type: "Klassische Arbeitshose.",
    url: "/classico-hose",
    img: (
      <ClassicoHoseFront
        lightContrast={COLORS.white.value}
        darkContrast={COLORS.black.value}
        stitching={COLORS.black.value}
        patchColor={COLORS.black.value}
        azomo={COLORS.white.value}
        preview={true}
      />
    ),
  },
  {
    id: 4,
    heading: "Shorts Classico",
    type: "Klassische Shorts mit vielen Taschen.",
    url: "/classico-shorts",
    img: (
      <ClassicoShortsFront
        lightContrast={COLORS.white.value}
        darkContrast={COLORS.black.value}
        stitching={COLORS.black.value}
        patchColor={COLORS.black.value}
        azomo={COLORS.white.value}
        preview={true}
      />
    ),
  },
  {
    id: 5,
    heading: "Hose Ultimate",
    type: "Top moderne 2-Weg Stretcharbeitshose.",
    url: "/ultimate-trousers",
    img: (
      <UltimateFront
        lightContrast={COLORS.white.value}
        darkContrast={COLORS.black.value}
        stitching={COLORS.black.value}
        patchColor={COLORS.black.value}
        azomo={COLORS.white.value}
        preview={true}
      />
    ),
  },
  {
    id: 6,
    heading: "Lazthose Primo",
    type: "Mit Seitenelast und verdecktem RV.",
    url: "/latzhose-primo",
    img: (
      <LatzhosePrimoFront
        lightContrast={COLORS.white.value}
        darkContrast={COLORS.black.value}
        stitching={COLORS.black.value}
        patchColor={COLORS.black.value}
        azomo={COLORS.white.value}
        preview={true}
      />
    ),
  },
  {
    id: 7,
    heading: "Overall Primo",
    type: "Mit Rückenfalte und verdecktem RV.",
    url: "/overall-primo",
    img: (
      <OverallPrimoFront
        lightContrast={COLORS.white.value}
        darkContrast={COLORS.black.value}
        stitching={COLORS.black.value}
        patchColor={COLORS.black.value}
        azomo={COLORS.white.value}
        preview={true}
      />
    ),
  },
  // {
  //   id: 8,
  //   heading: "Optica Hose",
  //   type: "Mit Rückenfalte und verdecktem RV.",
  //   url: "/optica",
  //   img: (
  //     <OpticaFront
  //       lightContrast={COLORS.white.value}
  //       darkContrast={COLORS.black.value}
  //       stitching={COLORS.black.value}
  //       patchColor={COLORS.black.value}
  //       azomo={COLORS.white.value}
  //       preview={true}
  //     />
  //   ),
  // },
];

export default function Pants() {
  const classes = useStyles();

  return (
    <Layout>
      <CssBaseline />
      <main>
        <Container className={classes.cardGrid} maxWidth="lg">
          <Grid container spacing={1}>
            {cards.map((card) => (
              <Grid item key={card.id} xs={12} sm={6} md={4}>
                <Card className={classes.card}>
                  <Link to={card.url}>{card.img}</Link>
                  <CardContent className={classes.cardContent}>
                    <Typography gutterBottom variant="h5" component="h2">
                      {card.heading}
                    </Typography>
                    <Typography>{card.type}</Typography>
                  </CardContent>
                  <CardActions>
                    <Button color="secondary" variant="contained" size="small">
                      <Link style={{color: "white"}} to={card.url}>
                        Wählen Sie das Farbschema
                      </Link>
                    </Button>
                  </CardActions>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Container>
      </main>
    </Layout>
  );
}
